<template>
  <div class="success-reserve">
    <div class="header">
      <div class="title">
        <div class="img">
          <img src="@/assets/success-reserve-right.png" />
        </div>
        <div class="text">预约成功</div>
      </div>
      <div class="subtitle">您已成功预约，面试信息如下</div>
    </div>
    <div class="body">
      <div class="card">
        <div class="title">面试信息</div>
        <div class="form">
          <div class="form-row">
            <div class="form-label">公司名称：</div>
            <div class="form-content">{{ dataInfo.companyName }}</div>
          </div>
          <div class="form-row">
            <div class="form-label">面试职位：</div>
            <div class="form-content">{{ dataInfo.positionName }}</div>
          </div>
          <div class="form-row">
            <div class="form-label">面试时间：</div>
            <div class="form-content">{{ dataInfo.date }}</div>
          </div>
          <div class="form-row">
            <div class="form-label">面试形式：</div>
            <div class="form-content">{{ getInterviewType(dataInfo.interviewMode) }}</div>
          </div>
          <div class="form-row">
            <div class="form-label">候选人姓名：</div>
            <div class="form-content">{{ dataInfo.candidateName }}</div>
          </div>
          <div v-if="dataInfo.interviewMode == interviewMode.offlineInterview" class="form-row">
            <div class="form-label">面试地址：</div>
            <div class="form-content">{{ dataInfo.interviewAddr }}</div>
          </div>
        </div>
      </div>
      <div class="action">
        <div class="submit" :class="{ disabled: dataInfo.modifyPermit <= 0 }" @click="edit()"><span>修改预约</span></div>
        <div class="tip">
          <div class="row">
            <span>每个候选人仅有两次修改面试机会</span>
          </div>
          <div class="row">
            <span>当前修改面试剩余机会为</span>
            <span>{{ dataInfo.modifyPermit }}</span>
            <span>次</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getReserveInfoByJobseeker } from '../../api/interview'
export default {
  name: 'SuccessReserve',
  data: () => {
    return {
      reserveId: '',
      jobseekerChannelId: '',
      dataInfo: {},
      interviewMode: {
        offlineInterview: 0,
        onlineInterview: 1,
        tencentInterview: 3,
      },
      modleData: [
        {
          value: 0,
          name: '线下面试',
        },
        {
          value: 1,
          name: '线上面试',
        },
        {
          value: 3,
          name: '腾讯面试',
        },
        {
          value: 4,
          name: '视频面试',
        },
      ],
    }
  },
  created() {
    // 获取基本数据
    const query = this.$route.query
    this.reserveId = query.reserveId
    this.jobseekerChannelId = query.jobseekerChannelId
    const param = {
      reserveId: this.reserveId,
      jobseekerChannelId: this.jobseekerChannelId,
    }
    getReserveInfoByJobseeker(param).then(res => {
      if (res.code === 0) {
        this.dataInfo = res.data
      }
    })
  },
  methods: {
    edit() {
      if (this.dataInfo.modifyPermit > 0) {
        this.$router.push({
          path: '/schedule-an-interview',
          query: {
            id: this.dataInfo.reserveCandidateId,
            reserveId: this.reserveId,
            jobseekerChannelId: this.jobseekerChannelId,
          },
        })
      }
    },
    getInterviewType(type) {
      const length = this.modleData.length
      for (let i = 0; i < length; i++) {
        if (parseInt(this.modleData[i].value) === parseInt(type)) {
          return this.modleData[i].name
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.success-reserve {
  background: url(../../assets/schedule-interview-header-bg.png) no-repeat;
  height: 100vh;
  background-color: #f5f5f5;
  background-size: 100%;
  .header {
    padding-top: 32px;
    padding-left: 30px;
    padding-bottom: 11px;
    .title,
    .subtitle {
      font-family: PingFangSC-Semibold, PingFang SC;
      color: #ffffff;
    }
    .title {
      display: flex;
      height: 30px;
      line-height: 30px;
      .img {
        height: 28px;
        margin-right: 10px;
        img {
          height: 0.7rem;
        }
      }
      .text {
        font-size: 22px;
        font-weight: 600;
      }
    }
    .subtitle {
      margin-top: 7px;
      height: 21px;
      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
    }
  }
  .body {
    .card {
      margin: 0 20px;
      background: #ffffff;
      box-shadow: 0px 0px 4px 0px rgba(141, 141, 141, 0.5);
      border-radius: 10px;
      padding: 24px 22px 40px 22px;
      .title {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 25px;
      }
      .form {
        padding-top: 30px;
        .form-row + .form-row {
          padding-top: 18px;
        }
        .form-row {
          display: flex;
          .form-label,
          .form-content {
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            line-height: 21px;
            font-weight: 400;
          }
          .form-label {
            min-width: 2.6rem;
            margin-right: 10px;
            color: #979797;
          }
          .form-content {
            color: #333333;
          }
        }
      }
    }
    .action {
      text-align: center;
      margin-top: 50px;
      .submit {
        margin: 0 auto;
        width: 320px;
        height: 52px;
        background: #4f8fff;
        border-radius: 26px;
        padding: 13px;
        span {
          width: 72px;
          height: 25px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 25px;
          color: #ffffff;
        }
      }
      .disabled {
        cursor: not-allowed;
        background: #dddddd;
        border-color: #dddddd;
      }
      .tip {
        text-align: center;
        margin-top: 20px;
        .row {
          width: 270px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #aaaaaa;
          line-height: 20px;
          margin: 0 auto;
          span:nth-child(2) {
            color: #f04d4d;
          }
        }
      }
    }
  }
}
</style>
